/* overwrite colors for side-panel tabs */

:root {
  --color-primary-50: #F4F3FF;
  --color-primary-100: #EBE9FE;
  --color-primary-200: #D9D6FE;
  --color-primary-300: #BDB4FE;
  --color-primary-400: #9B8AFB;
  --color-primary-500: #7A5AF8;
  --color-primary-600: #6938EF;
  --color-primary-700: #5925DC;
  --color-primary-800: #4A1FB8;
  --color-primary-900: #3E1C96;
  --color-gray-50: #F9FAFB;
  --color-gray-100: #F2F4F7;
  --color-gray-200: #EAECF0;
  --color-gray-300: #D0D5DD;
  --color-gray-400: #98A2B3;
  --color-gray-500: #667085;
  --color-gray-600: #475467;
  --color-gray-700: #344054;
  --color-gray-800: #1D2939;
  --color-gray-900: #101828;
  --font-size-base: 14px;
  --line-height-base: 1.5;
  --font-weight-medium: 500;
  --color-gradStart:#8C66F4;
  --color-gradEnd:#7C3ABB;
  --sidebar-gradient:linear-gradient(90deg, var(--color-gradStart) 0%, var(--color-gradEnd) 100%);
  --gradient-primary:linear-gradient(92.84deg, var(--color-primary-600) 9.08%, var(--color-primary-400) 115.99%);
}


.ant-btn-primary{
  background: var(--gradient-primary) !important;
}


.editor-container .polotno-side-tabs-container {
  // background-image: url('https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/editor_sidebar_bg.png') !important;
  background: var(--gradient-primary);
  width: 100px;
  background-size: cover;
  font-size: '12px' !important;
  font-weight: 200;
  padding-top: 20px;
}

.editor-container .polotno-side-tabs-container .polotno-side-panel-tab:hover,
.editor-container .polotno-side-tabs-container .polotno-side-panel-tab.active {
  display: flex;
  flex-direction: column;
  background: var(--sidebar-gradient);
  transition: background 0.6s ease-in, color 0.5s ease-in;
  border-left: #ffffff 2px solid;
  width: 100px;
}

.polotno-side-panel-tab .span {
  color: 'black';
  font-size: 17px !important;
}

#root
  > div
  > div:nth-child(2)
  > div
  > div
  > div.go1236784588
  > div
  > div.go1430256704.polotno-side-tabs-container
  > div
  > div
  > div
  > span {
  font-size: 17px !important;
  font-weight: 400;
}

.editor-container .polotno-side-tabs-container .polotno-side-panel-tab {
  color: white;
  transition: background 0.4s ease-out, color 0.4s ease-out;
  margin: 10px 0 10px;
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  fill: white !important;
}

/* change hover style of all buttons */
.editor-container .bp5-button:not([class*='editor-container-intent-']):hover {
  background-color: var(--color-primary-500);
}

.polotno-panel-container {
  background: var(--color-primary-50);
  border: 0px;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  .bp5-navbar {
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0), 0 1px 1px rgba(17, 20, 24, 0) !important;
  }
}

.right-side-bar {
  position: relative;
  right: 0;
  width: 100px;
  height: 100%;
  // background: linear-gradient(90deg, rgba(106, 57, 239, 1) 0%, rgba(119, 52, 189, 1) 100%);
  background: linear-gradient(90deg, var(--color-primary-600) 0%, var(--color-primary-500) 100%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.bp5-icon-undo {
  color: var(--color-primary-500) !important;
}

.bp5-icon-more {
  color: var(--color-primary-500) !important;
}

.go2349920149 {
  display: none;
}
.go3456988929 {
  padding: 20px;
  background: transparent;
  border-radius: 20px 20px 20px 20px !important;

  .bp5-navbar {
    border-radius: 20px 20px 0 0 !important;
    border-bottom: 1px solid var(--color-gray-200) !important;
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0), 0 1px 1px rgba(17, 20, 24, 0) !important;
  }
}

.bp5-button.bp5-minimal {
  background: none; /* Remove any default background */
  border: none; /* Remove any default border */
  box-shadow: none; /* Remove any default shadow */
}

.bp5-icon.bp5-icon-time {
  margin-right: 8px; /* Space between icon and text */
}

.bp5-button-text {
  color: var(--color-gray-400); /* Color for the text, adjust as needed */
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  font-style: medium;
}

.bp5-button-text:hover{
  color: var(--color-gray-300); 
}

.editor-container .bp5-button:not([class*='editor-container-intent-']):hover {
  background-color: var(--color-primary-400);
  color: var(--color-gray-200);
}


// .bp5-minimal{
//   display: none !important;
// }

/* overwrite styles for nav-bar tabs */

/* Loader */

.phone-container {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  direction: 'row';
  height: 100vh;
}
.iphone-case {
  --border-radius-outer: 2rem;
  --outer-padding: 0.5rem;
  --case-color: hsl(0, 0%, 0%);
  --border-radius-inner: calc(var(--border-radius-outer) - var(--outer-padding));
  box-shadow: 0px 0px 0px 2px white;
  background-color: #f7f7f7;
  border-radius: var(--border-radius-outer);
  padding: var(--outer-padding);
  width: min(350px);
  aspect-ratio: 1/2;
  position: relative;
  margin-inline: auto;
  border: 2px solid var(--color-primary-600);
}
.iphone-case > .child-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  // flex-direction: row;
}
.iphone-case .gradient-1 {
  --gradient-stop-1: 30%;
  border-top-left-radius: var(--border-radius-inner);
  border-top-right-radius: var(--border-radius-inner);
  border-bottom: 3px solid var(--base-color);
  box-shadow: 0px 2px 4px var(--base-color);
  background-image: -webkit-radial-gradient(
    circle,
    var(--start-color) var(--gradient-stop-1),
    var(--color-stop-center),
    var(--base-color) 90%
  );
  background-image: radial-gradient(
    circle,
    var(--start-color) var(--gradient-stop-1),
    var(--color-stop-center),
    var(--base-color) 90%
  );
  background-position: 50% 200%;
  background-size: 100% 200%;
}
.iphone-case .gradient-2 {
  --gradient-stop-1: 65%;
  --gradient-stop-2: 80%;
  border-bottom-left-radius: var(--border-radius-inner);
  border-bottom-right-radius: var(--border-radius-inner);
  border-top: 3px solid var(--base-color);
  box-shadow: 0px -2px 4px var(--base-color);
  background-image: -webkit-radial-gradient(
    circle,
    var(--start-color) var(--gradient-stop-1),
    var(--color-2, hsl(0, 0%, 0%)) var(--gradient-stop-2),
    var(--base-color) 100%
  );
  background-image: radial-gradient(
    circle,
    var(--start-color) var(--gradient-stop-1),
    var(--color-2, hsl(0, 0%, 0%)) var(--gradient-stop-2),
    var(--base-color) 100%
  );
  background-position: 50% 100%;
}
.case-1 .gradient-1 {
  --base-color: #fdc1f7;
  --color-stop-center: (var(--color-primary-600), #c84fe4);
}
.case-1 .gradient-2 {
  --color-2: var(--color-primary-600);
  --base-color: var(--color-primary-400);
}

.iphone-case:before {
  content: '';
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.3rem;
  background-color: var(--color-primary-600);
  width: 22%;
  border-radius: 25px;
}

.progress-bar_ {
  display: flex;
  height: 200px;
  align-items: end;
  direction: 'coloumn';
  justify-content: center;
}


// slider
.ant-slider .ant-slider-track {
  background: var(--color-primary-600) !important;
}

.ant-slider .ant-slider-handle {
  border-color: var(--color-primary-600) !important;
}

.ant-slider .ant-slider-rail {
  background: var(--color-primary-200) !important; 
}

.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 2px var(--color-primary-400) !important;
}

.ant-switch {
  &-checked {
    background-color: var(--color-primary-600) !important
  }
  &-unchecked {
    background-color: var(--color-primary-200) !important
  }
  
  &-handle {
    &::before {
      background-color: var(--color-gray-200) !important
    }
  }
}

.ant-tabs-tab-btn{
  color: var(--color-primary-600) !important;
}

.ant-tabs-tab {
  &:hover {
    color: var(--color-primary-600) !important;
  }
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-primary-600) !important;
}

.ant-tabs-ink-bar {
  background-color: var(--color-primary-500) !important;
}



.bp5-tabs ,.bp5-tab{
  .bp5-tab[aria-selected='true'] {
    color: var(--color-primary-500) // Color for the selected tab text
  }
  
  .bp5-tab:not([aria-disabled='true']):hover {
    color: var(--color-primary-500) // Color for the hovered tab text
  }
  
  // Additional styles for the tab container or other elements can go here
  .ant-tabs-nav {
    background-color: #E6F7FF; // Set your desired background color for the tab navigation
    border-bottom: 1px solid var(--color-primary-500); // Set your desired border color
  }
  
  .ant-tabs-ink-bar {
    background-color: var(--color-primary-500) !important // Ink bar color
  }
}

@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;